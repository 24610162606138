import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { Button, Text, Stack, TextField, Checkbox, Radio, Select, theme } from '@veeqo/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppContext, defaultInitialState } from 'context/Context';
import InputGroup from 'components/common/InputGroup';
import TermsConditions from 'components/Terms/TermsConditions';
import { fetchSuggestions, fetchLocation } from 'utils/awsLocationClient';
import { submitSignupData, SignupResponse } from 'utils/api';
import useDefaultParamsCountry from 'utils/useDefaultParamsCountry';
import states from 'utils/states.json';
import monthlyOrderVolumes from 'utils/monthlyOrderVolumes.json';
import formValidations from 'utils/validations';
import { trackSegmentEvent } from 'Marketing/utils/helpers';
import { Action, Event, Location } from 'Marketing/types';
import { accountCreationTracking } from 'Marketing/accountCreatedTracking';
import { isMonthlyOrderVolumeVisible } from 'utils/isMonthlyOrderVolumeVisible';
import { MARKETING_OPT_IN_COPY } from '../../constants';
import Divider from 'components/common/Divider';

type FormData = {
  companyName: string;
  addressLine1: string;
  country: string;
  city: string;
  region: string;
  postCode: string;
  phone: string;
  marketingConsent: boolean;
  monthlyOrderVolume: string;
  referralCode: string;
};

type AddressSearch = {
  suggested: boolean;
  value: string;
};

const Form = styled.form`
  .form__submit-button {
    width: 100%;
  }

  .form__login-button,
  .form__email-button {
    flex: 1 0 auto;
  }

  .stack--full-width {
    width: 100%;
  }
`;

export const Hint = styled.span`
  display: block;
  font-family: ${theme.fontFamily};
  font-size: ${theme.sizes[3]};
  line-height: ${theme.sizes[4]};
  color: ${theme.colors.neutral.ink.light};
`;

const countryOptions = [
  { value: 'US', label: 'United States' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'other', label: 'Other' },
];

const CompanyDetailsForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    actions,
    state: { signupData },
  } = useAppContext();
  const country = useDefaultParamsCountry();
  const isSelectedCountryUS = country === 'US';
  const postCodeValidationMessage = 'Please enter a valid postcode/zip code';

  const defaultValues = signupData;
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const [showManual, setShowManual] = useState(false);
  const showManualOrAnyError = showManual || Object.keys(errors).length;
  const [address, setAddress] = useState<AddressSearch>({
    suggested: false,
    value: '',
  });
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState('');
  const shouldShowMonthlyOrderSelect = useMemo(() => isMonthlyOrderVolumeVisible(), []);

  const resetAddressFields = (value: string) => {
    actions.setSignupData({ country: value });
    setAddress({ suggested: false, value: '' });
    setSuggestions([]);
    setShowManual(false);
    reset({ country: value, companyName: getValues('companyName') });
  };

  const onCountryChange = (value: string) => {
    if (value === 'other') {
      navigate('/signup/coming-soon');
    }
    resetAddressFields(value);
  };

  const handleManualAddressClick = () => {
    setShowManual(true);
    trackSegmentEvent(Event.BUTTON_CLICKED, {
      location: Location.SIGNUP_COMPANY_DETAILS,
      action: Action.ENTER_ADDRESS_MANUALLY,
    });
  };

  const handleSignupError = (error_messages: string[]) => {
    if (error_messages.includes('Email has already been taken')) {
      return setSignupErrorMessage(
        'This email is currently in use. Please login to continue or try another email',
      );
    }
    if (error_messages.includes('Email is invalid')) {
      return setSignupErrorMessage('Please enter a valid email address');
    }
    if (error_messages.includes('Post code format is not valid, please check')) {
      return setSignupErrorMessage(postCodeValidationMessage);
    }
    // handle unspecified use case
    if (error_messages.length >= 1) {
      return setSignupErrorMessage(error_messages[0]);
    }
    // default
    setSignupErrorMessage('There has been an error, please try again');
  };

  const onSubmit = handleSubmit(async (formData: FormData) => {
    setIsButtonLoading(true);

    const submitPayload = {
      ...signupData,
      ...formData,
      phone: formData.phone?.trim(),
      country,
      termsAndConditions: true,
    };
    accountCreationTracking(submitPayload);
    const pathPrefix = location.pathname.startsWith('/vq') ? '/vq' : ''
    const response: SignupResponse | undefined = await submitSignupData(submitPayload, pathPrefix);

    if (response?.error_messages) {
      setIsButtonLoading(false);
      handleSignupError(response.error_messages);
    } else {
      localStorage.removeItem('MonthlyOrderVolumeSelectEnabled');
    }
  });

  useEffect(() => {
    if (address.value.length > 1 && !address.suggested) {
      fetchSuggestions(address.value, country).then(setSuggestions);
    }
  }, [address, country]);

  useEffect(() => {
    if (location.pathname.startsWith('/vq'))
      return;
    if (!signupData.email) {
      if (global.location.host === 'app.veeqo.com') {
        navigate('/signup');
      } else {
        // *.veeqo.amazon.dev & direct signed s3 urls
        // eslint-disable-next-line no-console
        console.log("should navigate('/signup');");
      }
    }
  });

  const selectedSuggestion = (text: string) => {
    setAddress({ suggested: true, value: text });
    fetchLocation(text, country).then((location) => {
      if (location) {
        Object.entries(location).map(([key, val]) => {
          const userCountry = signupData?.country || defaultInitialState.signupData.country;
          return setValue(key as keyof FormData, key === 'country' ? userCountry : val);
        });
        setShowManual(true);
      }
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Stack spacing={6}>
        <InputGroup>
          <Controller
            name="companyName"
            control={control}
            rules={formValidations.companyName}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                placeholder="Business name"
                aria-label="company name"
                label="Company Name"
                hint="This name will appear on invoices and shipping labels"
                error={errors.companyName && errors.companyName.message}
                className="fs-exclude"
              />
            )}
          />
        </InputGroup>
        {
          /* Seller info group */
          shouldShowMonthlyOrderSelect && (
            <InputGroup>
              <Controller
                name="monthlyOrderVolume"
                control={control}
                rules={formValidations.monthlyOrderVolume}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Orders per month"
                    value={value}
                    options={monthlyOrderVolumes}
                    onChange={onChange}
                    error={errors.monthlyOrderVolume && errors.monthlyOrderVolume.message}
                    className="fs-exclude"
                  />
                )}
              />
            </InputGroup>
          )
        }
        {/* Address Group */}
        <InputGroup style={{ marginTop: '1rem' }}>
          <Controller
            name="country"
            control={control}
            rules={formValidations.country}
            render={({ field: { value } }) => (
              <Select
                label="Country"
                value={value}
                options={countryOptions}
                onChange={(e) => {
                  onCountryChange(e);
                }}
              />
            )}
          />
        </InputGroup>
        <InputGroup>
          <TextField
            onChange={(value) => setAddress({ suggested: false, value })}
            value={address.value}
            aria-label="company address"
            label="Company Address"
            placeholder="Search your address"
            hint="Your company location is used to calculate shipping rates"
            className="fs-exclude"
          />
        </InputGroup>
        {!address.suggested
          ? suggestions.map((text, idx) => (
              <span className="fs-exclude">
                <Radio
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  label={text}
                  checked={false}
                  onChange={() => selectedSuggestion(text)}
                />
              </span>
            ))
          : null}

        {!showManualOrAnyError && (
          <Button variant="link" onClick={handleManualAddressClick}>
            Enter address manually
          </Button>
        )}
        <InputGroup style={showManualOrAnyError ? {} : { display: 'none' }}>
          <Controller
            name="addressLine1"
            control={control}
            rules={formValidations.addressLine1}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                placeholder="Address"
                aria-label="address"
                label="Address"
                error={errors.addressLine1 && errors.addressLine1.message}
                className="fs-exclude"
              />
            )}
          />
        </InputGroup>
        {isSelectedCountryUS ? (
          <InputGroup style={showManualOrAnyError ? {} : { display: 'none' }}>
            <Controller
              name="city"
              control={control}
              rules={formValidations.city}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label="City"
                  placeholder="City"
                  aria-label="city"
                  error={errors.city && errors.city.message}
                  className="fs-exclude"
                />
              )}
            />
          </InputGroup>
        ) : null}
        <Stack
          direction="horizontal"
          className="stack--full-width"
          style={showManualOrAnyError ? {} : { display: 'none' }}
        >
          {isSelectedCountryUS ? (
            <InputGroup>
              <Controller
                name="region"
                control={control}
                rules={formValidations.region}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="State"
                    value={value}
                    options={states}
                    onChange={onChange}
                    error={errors.region && errors.region.message}
                    className="fs-exclude"
                  />
                )}
              />
            </InputGroup>
          ) : (
            <InputGroup>
              <Controller
                name="city"
                control={control}
                rules={formValidations.city}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    label="City"
                    placeholder="City"
                    aria-label="city"
                    error={errors.city && errors.city.message}
                    className="fs-exclude"
                  />
                )}
              />
            </InputGroup>
          )}
          <InputGroup>
            <Controller
              name="postCode"
              control={control}
              rules={formValidations.postCode}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  placeholder={isSelectedCountryUS ? '12345' : 'SA1 1NW'}
                  aria-label={isSelectedCountryUS ? 'zipcode' : 'postcode'}
                  label={isSelectedCountryUS ? 'Zipcode' : 'Postcode'}
                  error={errors.postCode && errors.postCode.message}
                  className="fs-exclude"
                />
              )}
            />
          </InputGroup>
        </Stack>
        {/* Address Group */}
        <Stack spacing={6} className="stack--full-width">
          <InputGroup>
            <Controller
              name="phone"
              control={control}
              rules={formValidations.phone}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  aria-label="phone number"
                  label="Phone Number"
                  placeholder={isSelectedCountryUS ? '+1 123 456 7890' : '+44 0203 808 9165'}
                  error={errors.phone && errors.phone.message}
                  className="fs-exclude"
                />
              )}
            />
          </InputGroup>
          {!isSelectedCountryUS && (
            <AnimatePresence>
              <motion.div initial={{ height: 0 }} animate={{ height: 'auto' }}>
                <Controller
                  name="marketingConsent"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox label={MARKETING_OPT_IN_COPY} onChange={onChange} checked={value} />
                  )}
                />
              </motion.div>
            </AnimatePresence>
          )}
        </Stack>
        <Divider />
        <InputGroup>
          <Controller
            name="referralCode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                aria-label="referral code"
                type="text"
                label="Referral code"
                placeholder="Referral code"
                error={errors.referralCode && errors.referralCode.message}
              />
            )}
          />
        </InputGroup>
        {signupErrorMessage && !signupErrorMessage.includes(postCodeValidationMessage) && (
          <Stack direction="vertical" spacing={6} style={{ width: '100%' }} alignX="center">
            <Text variant="errorSmall">{signupErrorMessage}</Text>
            <Stack direction="horizontal" spacing={6} style={{ width: '100%' }} alignX="center">
              <Button
                variant="default"
                onClick={() => navigate('/signin')}
                className="form__login-button"
              >
                Login
              </Button>
              <Button
                variant="primary"
                onClick={() => navigate('/signup')}
                className="form__email-button"
              >
                Enter a new email
              </Button>
            </Stack>
          </Stack>
        )}
        {signupErrorMessage && signupErrorMessage.includes(postCodeValidationMessage) && (
          <Stack direction="vertical" spacing={6} style={{ width: '100%' }} alignX="center">
            <Text variant="errorSmall">{signupErrorMessage}</Text>
            <Button
              type="submit"
              variant="primary"
              loading={isButtonLoading}
              disabled={isButtonLoading}
              className="form__submit-button"
            >
              Connect store
            </Button>
          </Stack>
        )}
        {!signupErrorMessage && (
          <Button
            type="submit"
            variant="primary"
            loading={isButtonLoading}
            disabled={isButtonLoading}
            className="form__submit-button"
          >
            Connect store
          </Button>
        )}
        <TermsConditions country={country} />
      </Stack>
    </Form>
  );
};

export default CompanyDetailsForm;
