import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Signup from 'components/Signup/Signup';
import WaitingList from 'components/ContingencyWaitingList/WaitingList';
import ThirdPartyScripts from 'Marketing/ThirdPartyScripts';
import { LoginRoutes } from 'components/Login/Routes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <>
      <ThirdPartyScripts />
      <AnimatePresence mode="wait" initial={false}>
        <SentryRoutes key="Signup App">
          <Route path="/signin/*" Component={LoginRoutes} />
          <Route path="/signup/waiting-list" element={<WaitingList />} />
          <Route path="/static_content/waiting-list.html" element={<WaitingList />} />
          <Route path="/signup/*" element={<Signup />} />
          <Route path="/vq/signup/*" element={<Signup />} />
        </SentryRoutes>
      </AnimatePresence>
    </>
  );
}

export default App;
