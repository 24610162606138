/**
 * # API Responses
 *
 * ## Endpoint: `${backendHost}${backendSignupUrl}`
 *
 * This endpoint is used for submitting signup data. The response can vary depending on the status code and the response body.
 *
 * ### Response Cases
 *
 * 1. **Status Code: 200 (OK), Response Body: `{ redirect_to: 'some_url' }`**
 *    - Description: This response indicates a successful signup. The `redirect_to` property in the response body contains
 * the URL to which the client should be redirected.
 *
 * 2. **Status Code: 400 (Bad Request), Response Body: `{ error_messages: ['error_message_1', 'error_message_2', ...] }`**
 *    - Description: This response indicates an error during the signup process. The `error_messages` array in the response
 * body contains a list of error messages.
 *
 * 3. **Status Code: 400 (Bad Request), Response Body: `{ status: 400, error: 'Bad request' }`**
 *    - Description: This response indicates an unhandled generic error during the signup process.
 *
 * ## Endpoint: `${backendHost}${backendLoginUrl}`
 *
 * This endpoint is used for submitting login data. The response can vary depending on the status code and the response body.
 *
 * ### Response Cases
 *
 * 1. **Status Code: 200 (OK), Response Body: `{ redirect_to: 'some_url' }`**
 *    - Description: This response indicates a successful login. The `redirect_to` property in the response body contains
 * the URL to which the client should be redirected.
 *
 * 2. **Status Code: 200 (OK), Response Body: `{ authentication_required: true }`**
 *    - Description: This response indicates a successful login, but additional check (e.g., verification code) is required.
 *
 * 3. **Status Code: 401 (Unauthorized), Empty JSON Body**
 *    - Description: This response indicates a failed login attempt.
 *
 * ## Endpoint: `${backendHost}${backendLoginVerifyUrl}`
 *
 * This endpoint is used for verifying the 2FA code. The response can vary depending on the status code and the response body.
 *
 * ### Response Cases
 *
 * 1. **Status Code: 401 (Unauthorized), Empty JSON Body**
 *    - Description: This response case is being deprecated. It represents an authentication failure with an empty response body.
 * Further requests will fail with same message
 *
 * 2. **Status Code: 302 (Redirect)**
 *    - Description: This response case is being deprecated.
 *
 * 3. **Status Code: 200 (OK), Response Body: `{ redirect_to: 'some_url' }`**
 *    - Description: This response indicates a successful verification. The `redirect_to` property in the response body
 * contains the URL to which the client should be redirected.
 *
 * 4. **Status Code: 401 (Unauthorized), Response Body: `{ remaining_attempts: 3 }`**
 *    - Description: This response indicates a failed verification attempt. The `remaining_attempts`
 * property in the response body indicates the number of remaining attempts. All subsequent requests will fail
 * after 60 seconds is passed
 */

/* eslint-disable @typescript-eslint/naming-convention */
import { fetchWithCsrf } from 'utils/fetchWithCsrf';
import * as Sentry from '@sentry/react';
import {
  backendHost,
  backendSignupUrl,
  backendLoginUrl,
  apiHeaders,
  backendLoginVerifyUrl,
} from 'utils/constants';
import { LoginData, SignupData } from 'context/types';
import { formDataSanitiser } from 'utils/formDataSanitiser';
import { trackAccountCreated } from './adobeAnalytics/afterAuth/trackAccountCreated';
import { trackUserSigninSuccess } from './adobeAnalytics/afterAuth/trackUserSigninSuccess';
import { reportUserDataMissing } from './adobeAnalytics/utils';

export type SignupResponse = {
  redirect_to?: string;
  error_messages?: string[];
  // Required for AA.
  user_id?: string;
  company_id?: string;
  company_name?: string;
};

/**
 * is_employee: indicates whether the logged in user is the owner of the company or not. The BE 
 * does not consider company owners as "employees". A user is considered an employee only if the 
 * owner has created an account for them via settings. 
 */
export type LoginResponse = {
  redirect_to?: string;
  error?: boolean;
  isVerificationRequired?: boolean;
  // Required for AA.
  user_id?: string;
  company_id?: string;
  company_name?: string;
  // Required for segment identify
  is_employee?: boolean // Note - an employee is not considered an owner of a company. 
};

type VerifyResponse = {
  redirect_to?: string;
  user_id?: string;
  company_id?: string;
  company_name?: string;
  remaining_attempts?: number;
};

export const decamelizedSignupData = (data: SignupData) => {
  const {
    country,
    email,
    login,
    password,
    companyName,
    addressLine1,
    city,
    region,
    postCode,
    phone,
    marketingConsent,
    termsAndConditions,
    amazonOauthAccessToken,
    amazonOauthUid,
    timezone,
    referralCode,
    referralCompanyID,
  } = data;
  const result = {
    login,
    email,
    city,
    region,
    timezone,
    post_code: postCode,
    address_line_1: addressLine1,
    company_name: companyName,
    country,
    phone,
    terms_and_conditions: termsAndConditions,
    marketing_consent: marketingConsent,
    referral_code: referralCode,
    referral_company_id: referralCompanyID,
  };
  // TODO: return url submit
  if (amazonOauthUid?.length && amazonOauthAccessToken?.length) {
    return {
      ...result,
      amazon_oauth_uid: amazonOauthUid,
      amazon_oauth_access_token: amazonOauthAccessToken,
    };
  }
  return { ...result, password };
};

export const submitSignupData = async (submitData: SignupData, prefix = '') => {
  const sanitisedData = formDataSanitiser(submitData);

  try {
    const response = await fetchWithCsrf(`${backendHost}${prefix}${backendSignupUrl}`, {
      method: 'POST',
      mode: backendHost?.length ? 'cors' : 'same-origin',
      headers: apiHeaders,
      body: JSON.stringify({ signup: decamelizedSignupData(sanitisedData) }),
    });

    const { redirect_to, error_messages, user_id, company_id }: SignupResponse =
      await response.json();

    if (redirect_to) {
      // expected location has sellercentral url to start or continue the channel flow
      global.location.assign(redirect_to);

      if (!!user_id && !!company_id) {
        if (!submitData.companyName) Sentry.captureMessage('AA: company name is missing');
        trackAccountCreated(user_id, company_id, submitData.companyName || '');
      } else {
        reportUserDataMissing({ user_id, company_id, company_name: submitData.companyName });
      }
    }

    if (error_messages) {
      return { error_messages };
    }
  } catch (e) {
    return { error_messages: [] };
  }
};

export const submitLogin = async (loginData: LoginData) => {
  const sanitisedData = formDataSanitiser(loginData);

  try {
    const response = await fetchWithCsrf(`${backendHost}${backendLoginUrl}`, {
      method: 'POST',
      mode: backendHost?.length ? 'cors' : 'same-origin',
      headers: apiHeaders,
      body: JSON.stringify(sanitisedData),
    });
    const { redirect_to, authentication_required, user_id, company_id, company_name, is_employee } =
      await response.json();
    return {
      redirect_to,
      isVerificationRequired: authentication_required,
      user_id,
      company_id,
      company_name,
      is_employee,
    };
  } catch (e) {
    /**
     * To prevent 'Information leak based on failed login attempts' we do not provide
     * any feedback which field caused the login failure (email, password or both)
     * BE returns 401 Unauthorized response status code and we show generic:
     * "Wrong credentials. Try again or click Forgot password to reset it."
     */
    return { error: true };
  }
};

export const verifyCode = async (data: { code: string }) => {
  try {
    const response = await fetchWithCsrf(`${backendHost}${backendLoginVerifyUrl}`, {
      method: 'POST',
      mode: backendHost?.length ? 'cors' : 'same-origin',
      headers: apiHeaders,
      body: JSON.stringify(data),
    });
    const { redirect_to, remaining_attempts, user_id, company_id, company_name }: VerifyResponse =
      await response.json();

    if (redirect_to) {
      global.location.href = redirect_to;

      if (!!user_id && !!company_id && !!company_name) {
        trackUserSigninSuccess(user_id, company_id, company_name);
      } else {
        reportUserDataMissing({ user_id, company_id, company_name });
      }
      return { redirectTo: redirect_to };
    }

    if (remaining_attempts !== undefined) {
      return remaining_attempts === 0 ? { error: true } : { error: true, isRetryable: true };
    }

    throw new Error(`status=${response.status}, response=${JSON.stringify(await response.json())}`);
  } catch (error) {
    // Currently, the backend may send empty JSON responses, as the fix has not been deployed yet.
    // No need to notify sentry
    if (`${error}` === 'SyntaxError: Unexpected end of JSON input') {
      return { error: true };
    }
    Sentry.captureMessage(`Unexpected error happened when verifying code - ${error}`);
    // eslint-disable-next-line no-console
    console.error('Error:', error);

    return { error: true };
  }
};
