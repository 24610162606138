import { identifySegmentEvent, trackSegmentEvent } from './utils/helpers';
import { Action, Event, Location, Target } from './types';

/**
 * Track login initiated via Amazon SSO
 */
export const ssoLoginTracking = (country: string | undefined) => {
  identifySegmentEvent(
    {
      country,
      password: '', // keep empty
    },
    { salesforce: false },
  );
  trackSegmentEvent(
    Event.BUTTON_CLICKED,
    {
      location: Location.LOGIN_HOMEPAGE,
      action: Action.LOGIN_WITH_AMAZON,
      target: Target.AMAZON_SSO_LOGIN,
      country,
    },
    { salesforce: false },
  );
};

/**
 * Track login initiated via email
 * 
 * @property {boolean | undefined} is_employee used to control whether the the identify call is triggered. We 
 * need this to ensure that the identify segment event is only sent for owner's of companies, as it otherwise
 * affects upstream segment dependencies (specifically salesforce integrations). It is not needed for LWA, as 
 * employees of companies cannot do this.
 */
export const loginTracking = (email: string, is_employee?: boolean) => {
  if (!is_employee) {
    identifySegmentEvent(
      {
        email,
        password: '', // keep empty
      },
      { salesforce: false },
    );
  }
  trackSegmentEvent(
    Event.BUTTON_CLICKED,
    {
      location: Location.LOGIN_HOMEPAGE,
      action: Action.LOGIN_WITH_EMAIL,
      target: Target.VEEQO_APP,
      email,
    },
    { salesforce: false },
  );
};
